import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment pdpInfoBlock on Block {
        ...pageinfoEntry
        title
        content {
            json
            links {
                entries {
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                    }
                }
            }
        }
    }
    fragment pdpInfoSection on Block {
        ...pageinfoEntry
        title
        componentsCollection(limit: 10) {
            items {
                ...pdpInfoParametric
            }
        }
    }
    fragment pdpInfoParametric on Parametric {
        ...pageinfoEntry
        attributeName
        title
        suffix
        prefix
    }
    fragment pdpInfo on ProductPageInfo {
        dictionary {
            ...pageinfoEntry
            listCollection(limit: 200) {
                items {
                    name
                    key
                    value
                }
            }
        }
        uspsCollection(limit: 6) {
            items {
                ... on Block {
                    ...pdpInfoBlock
                }
            }
        }
        stockInfo {
            ... on Block {
                ...pdpInfoBlock
            }
        }
        placeOrderForm {
            ...pageinfoForm
        }
        matchPriceForm {
            ...pageinfoForm
        }
        newsletterForm {
            ...pageinfoForm
        }
        parametricsCollection(limit: 10) {
            items {
                ...pdpInfoSection
            }
        }
        madeToOrderInfo {
            title
            content {
               json
            }
        }
    }
`);

export default graphql(/* GraphQL */ `
    query ProductInfo(
        $locale: String!
        $brand: [String!]!
        $designer: [String!]!
        $preview: Boolean
        $recommendedPriceTextBlockName: String!
    ) {
        brand: pageCollection(
            where: { commerceId_in: $brand, commerceType: "Brands" }
            locale: $locale
            limit: 5
            preview: $preview
        ) {
            items {
                __typename
                sys {
                    id
                }
                type
                slug
                title
                mobileImage {
                    ...contentfulAsset
                }
                desktopImage {
                    ...contentfulAsset
                }
                subtitle
                shortDescription
            }
        }
        designer: pageCollection(
            where: { commerceId_in: $designer, commerceType: "Designers" }
            locale: $locale
            limit: 5
            preview: $preview
        ) {
            items {
                __typename
                sys {
                    id
                }
                type
                slug
                title
                mobileImage {
                    ...contentfulAsset
                }
                desktopImage {
                    ...contentfulAsset
                }
                subtitle
                shortDescription
            }
        }
        productPageInfoCollection(limit: 1, locale: $locale) {
            items {
                ...pdpInfo
            }
        }
        recommendedPriceTextBox: textBlockCollection(
            locale: $locale
            where: { name: $recommendedPriceTextBlockName }
            limit: 1
        ) {
            items {
                ...textBlock
            }
        }
    }
`);
