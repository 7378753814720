import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment pageEntry on Entry {
        __typename
        sys {
            id
        }
    }
    fragment pageForm on Form {
        ...pageEntry
        name
        reference
        dictionaryCollection(limit: 100) {
            items {
                ... on KeyValue {
                    key
                    name
                    value
                }
            }
        }
    }
    fragment pageStore on Store {
        ...pageEntry
        title
        description {
            json
            links {
                entries {
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        type
        infoText
        phoneNumber
        email
        address
        city
        postalCode
        googleMapsUrl
        storePage {
            ... on Page {
                slug
            }
        }
        storePageLinkText
        openingHoursCollection(limit: 7) {
            items {
                name
                openingTime
                closingTime
                dayOfTheWeek
                closed
            }
        }
        exceptionalOpeningHoursCollection(limit: 5) {
            items {
                name
                date
                title
                closed
                closingTime
                openingTime
            }
        }
    }
    fragment pageAccordion on Accordion {
        ...pageEntry
        title
        text {
            json
            links {
                entries {
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                    }
                }
            }
        }
    }
    fragment pageEditorialRow on EditorialRow {
        ...pageEntry
        title
        numberOfColumns
        width
        spacing
        contentCollection(limit: 4) {
            items {
                ...pageEditorialBlock
                ...pageEditorialSplash
                ...pageEditorialProducts
                ...pageStore
            }
        }
    }
    fragment pageEditorialBlock on EditorialBlock {
        ...pageEntry
        content {
            json
            links {
                entries {
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                    }
                }
            }
        }
        verticalAlignment
    }
    fragment pageEditorialSplash on EditorialSplash {
        ...pageEntry
        type
        text {
            json
            links {
                entries {
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                    }
                    block {
                        ...pageNavigationLink
                        ...pageForm
                    }
                }
            }
        }
        textColor
        media
        mediaMobile
        imageText {
            json
            links {
                entries {
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                    }
                    block {
                        __typename
                        sys {
                            id
                        }
                    }
                }
            }
        }
    }
    fragment pageEditorialGallery on EditorialGallery {
        ...pageEntry
        imagesCollection(limit: 12) {
            items {
                ...pageEntry
                media
                imageText {
                    json
                }
            }
        }
    }
    fragment pageEditorialProducts on EditorialProducts {
        ...pageEntry
        commerceData
    }
    fragment pageLinkList on LinkList {
        ...pageEntry
        heading
        linksCollection(limit: 10) {
            items {
                ... on Page {
                    title
                    slug
                }
            }
        }
    }
    fragment pageNavigationLink on NavigationLink {
        ...pageEntry
        name
        type
        title
        display
        variant
        externalLink
        internalLink {
            ... on Page {
                slug
                commerceData
            }
            ... on Campaign {
                slug
            }
        }
    }
    fragment pageBrandBanner on BrandBanner {
        ...pageEntry
        bannerItemsCollection(limit: 10) {
            items {
                ...contentfulImage
            }
        }
    }
    fragment pageBlock on Block {
        ...pageEntry
        title
        content {
            json
        }
        componentsCollection(limit: 1) {
            items {
                ...pageSplash
                ...pageTextBlock
                ...pageContentRow
            }
        }
    }
    fragment pageTextBlock on TextBlock {
        ...pageEntry
        name
        type
        title
        titleIcon
        content {
            json
        }
    }
    fragment pageProductList on ProductList {
        ...pageEntry
        heading
        count
        commerceData
        link {
            ... on Page {
                slug
            }
        }
        linkText
    }
    fragment pageContentRow on ContentRow {
        ...pageEntry
        type
        numberOfColumns
        spacing
        devices
        contentCollection(limit: 3) {
            items {
                ...pageSplash
            }
        }
    }
    fragment pageSplash on Splash {
        ...pageEntry
        subheading
        header
        desktopImage {
            ...contentfulAsset
        }
        mobileImage {
            ...contentfulAsset
        }
        href {
            ... on Page {
                slug
            }
            ... on EditorialPage {
                slug
            }
            ... on LandingPage {
                slug
            }
            ... on Campaign {
                slug
            }
        }
        linkText
        type
        textColor
    }
    fragment pageHighlightRow on HighlightRow {
        ...pageEntry
        devices
        heading
        link {
            ... on Page {
                slug
            }
        }
        linkText
        spacing
        highlightContent: contentCollection(limit: 4) {
            items {
                ...pageSplash
            }
        }
    }
    fragment pageMyPageBlock on MyPageBlock {
        ...pageEntry
        type
        title
        description {
            json
            links {
                entries {
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        success {
            json
        }
        error {
            json
        }
    }
    fragment pageCustomerCommunicationPreferences on CustomerCommunicationPreferences {
        ...pageEntry
        name
        consentInfo {
            json
        }
    }
    fragment page on Page {
        ...pageEntry
        type
        title
        metaTitle
        metaDescription
        description {
            json
            links {
                entries {
                    block {
                        ...pageForm
                        ...pageStore
                        ...pageAccordion
                        ...pageEditorialRow
                        ...pageEditorialSplash
                        ...pageEditorialGallery
                        ...pageLinkList
                        ...pageHighlightRow
                        ...pageNavigationLink
                        ...pageMyPageBlock
                        ...pageCustomerCommunicationPreferences
                    }
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on Page {
                            slug
                        }
                    }
                }
            }
        }
        componentsCollection(limit: 10) {
            total
            items {
                ...pageForm
                ...pageBrandBanner
                ...pageBlock
                ...pageTextBlock
                ...pageProductList
                ...pageContentRow
                ...pageHighlightRow
            }
        }
        dictionary {
            name
            listCollection(limit: 150) {
                items {
                    key
                    name
                    value
                }
            }
        }
        shortDescription
        footerBanner(where: { activeMarkets_contains_some: $activeMarkets }) {
            ...footerBanner
        }
    }
`);

export default graphql(/* GraphQL */ `
    query Page(
        $locale: String!
        $filter: PageFilter!
        $limit: Int
        $skip: Int
        $order: [PageOrder]
        $preview: Boolean
        $activeMarkets: [String]
    ) {
        pageCollection(
            locale: $locale
            limit: $limit
            where: $filter
            order: $order
            skip: $skip
            preview: $preview
        ) {
            items {
                ...page
            }
        }
    }
`);
