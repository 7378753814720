import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment servicePageEntry on Entry {
        sys {
            id
        }
        __typename
    }
    fragment servicePageNavigation on Navigation {
        ...servicePageEntry
        name
        title
        sublinksCollection(limit: 8) {
            items {
                ...servicePageNavigationLink
            }
        }
    }
    fragment servicePageStore on Store {
        ...servicePageEntry
        title
        description {
            json
            links {
                entries {
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        type
        infoText
        phoneNumber
        email
        address
        city
        postalCode
        googleMapsUrl
        storePage {
            ... on Page {
                slug
            }
        }
        storePageLinkText
        openingHoursCollection(limit: 7) {
            items {
                name
                openingTime
                closingTime
                dayOfTheWeek
                closed
            }
        }
        exceptionalOpeningHoursCollection(limit: 5) {
            items {
                name
                date
                title
                closed
                closingTime
                openingTime
            }
        }
    }
    fragment servicePageEditorialRow on EditorialRow {
        ...servicePageEntry
        title
        numberOfColumns
        width
        spacing
        contentCollection(limit: 4) {
            items {
                ...servicePageEditorialBlock
                ...servicePageEditorialSplash
                ...servicePageEditorialProducts
                ...servicePageStore
            }
        }
    }
    fragment servicePageEditorialBlock on EditorialBlock {
        ...servicePageEntry
        content {
            json
            links {
                entries {
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                    }
                }
            }
        }
        verticalAlignment
    }
    fragment servicePageEditorialSplash on EditorialSplash {
        ...servicePageEntry
        type
        text {
            json
            links {
                entries {
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                    }
                    block {
                        ...servicePageNavigationLink
                        ...servicePageForm
                    }
                }
            }
        }
        textColor
        mobileImage {
            ...contentfulAsset
        }
        desktopImage {
            ...contentfulAsset
        }
        imageText {
            json
            links {
                entries {
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                    }
                    block {
                        __typename
                        sys {
                            id
                        }
                    }
                }
            }
        }
    }
    fragment servicePageEditorialGallery on EditorialGallery {
        ...servicePageEntry
        imagesCollection(limit: 12) {
            items {
                ...servicePageEntry
                media
                imageText {
                    json
                }
            }
        }
    }
    fragment servicePageEditorialProducts on EditorialProducts {
        ...servicePageEntry
        commerceData
    }
    fragment servicePageForm on Form {
        ...servicePageEntry
        name
        reference
        dictionaryCollection(limit: 100) {
            items {
                ... on KeyValue {
                    key
                    name
                    value
                }
            }
        }
    }
    fragment servicePageNavigationLink on NavigationLink {
        ...servicePageEntry
        name
        type
        title
        display
        variant
        externalLink
        internalLink {
            ... on Page {
                slug
                commerceData
            }
            ... on Campaign {
                slug
            }
        }
    }
    fragment servicePageAccordion on Accordion {
        ...servicePageEntry
        title
        text {
            json
            links {
                entries {
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                    }
                }
            }
        }
    }
    fragment servicePage on Page {
        ...servicePageEntry
        title
        ingress {
            json
        }
        image
        imageAltText
        mobileImage {
            ...contentfulAsset
        }
        desktopImage {
            ...contentfulAsset
        }
        description {
            json
            links {
                entries {
                    block {
                        ...servicePageEditorialRow
                        ...servicePageEditorialSplash
                        ...servicePageEditorialGallery
                        ...servicePageAccordion
                        ...servicePageForm
                    }
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        metaTitle
        metaDescription
        footerBanner(where: { activeMarkets_contains_some: $activeMarkets }) {
            ...footerBanner
        }
    }
`);

export default graphql(/* GraphQL */ `
    query ServicePage(
        $locale: String!
        $id: String!
        $sideMenuId: String!
        $preview: Boolean
        $activeMarkets: [String]
    ) {
        sideMenu(id: $sideMenuId, locale: $locale, preview: $preview) {
            ...servicePageEntry
            navigationsCollection(limit: 2) {
                items {
                    ...servicePageNavigation
                }
            }
            storeInfo {
                ...servicePageStore
            }
        }
        page(locale: $locale, id: $id, preview: $preview) {
            ...servicePage
        }
    }
`);
