import { z } from 'zod';

import { envConfig } from './env';

type LanguageConfiguration = {
    code: string;
    label: string;
    flag: string;
};

const english: LanguageConfiguration = {
    code: 'en',
    label: 'English',
    flag: 'gb',
};

export const EU_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE = 100;
export const EU_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE = 400;

export type MarketCode =
    | 'eu'
    | 'de'
    | 'ag'
    | 'at'
    | 'es'
    | 'nl'
    | 'be'
    | 'fr'
    | 'fi'
    | 'se'
    | 'no'
    | 'dk';

export const contentfulLocales = [
    'en-GB',
    'sv-SE',
    'nb-NO',
    'fi-FI',
    'da-DK',
    'nl-NL',
] as const;

export type ContentfulLocale = (typeof contentfulLocales)[number];

// would love to make this dynamic from the values above...
export const slugZodObject = z.object({
    'nb-NO': z.string().optional(),
    'sv-SE': z.string().optional(),
    'fi-FI': z.string().optional(),
    'da-DK': z.string().optional(),
    'en-GB': z.string().optional(),
    'nl-NL': z.string().optional(),
});

type WalleyCheckoutConfiguration =
    | {
          walleyCheckoutEnabled: true;
          walleyB2BStoreId: number;
          walleyB2CStoreId: number;
          walleyWidgetEnabled: boolean;
      }
    | {
          walleyCheckoutEnabled: false;
          walleyB2BStoreId?: never;
          walleyB2CStoreId?: never;
          walleyWidgetEnabled?: never;
      };

export type MarketConfiguration = WalleyCheckoutConfiguration & {
    code: MarketCode;
    label: string;
    testfreaksClientId: string;
    searchEngineUrl: string;
    contentfulLocale: ContentfulLocale;
    contentfulCountry: string;
    // should this be "slug" or something instead of locale?
    // is locale useful for sv, no?
    // Seems like it is only used for building urls / links
    locale: string;
    serviceLocale: string;
    language: LanguageConfiguration;
    currency: string;
    link: string;
    termsSlug: string;
    /**
     * Markets like EU don't have any data in Loop54
     * associated with their market code, so their
     * queries are done toward another market code.
     */
    loop54Market: string;
    adyenMarket: string;
    freeShippingThresholdSmallPackage: number;
    freeShippingThresholdLargePackage: number;
    externalStoreId: string;
    searchPathName: string;
    adyenCheckoutEnabled: boolean;
    hasTrustPilot: boolean;
};

// TODO:
/**
 * Move testfreaks client id from envConfig into here
 * Move search engine url from envconfig into here
 */

export const marketConfigurations: MarketConfiguration[] = [
    // EU
    {
        code: 'eu',
        label: 'EU',
        testfreaksClientId: envConfig.NEXT_PUBLIC_EU_TESTFREAKS_CLIENT_ID,
        searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL,
        contentfulLocale: 'en-GB',
        contentfulCountry: 'Germany',
        locale: 'eu',
        serviceLocale: 'en-de',
        language: english,
        currency: 'EUR',
        link: 'https://www.no-ga.com/eu',
        termsSlug: 'terms-online',
        loop54Market: 'de',
        adyenMarket: 'de',
        freeShippingThresholdSmallPackage:
            EU_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE,
        freeShippingThresholdLargePackage:
            EU_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE,
        externalStoreId: 'ecom-noga',
        searchPathName: 'search',
        adyenCheckoutEnabled: true,
        walleyCheckoutEnabled: false,
        hasTrustPilot: false,
    },
    // Germany
    {
        code: 'de',
        label: 'Germany',
        testfreaksClientId: envConfig.NEXT_PUBLIC_EU_TESTFREAKS_CLIENT_ID,
        searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL,
        contentfulLocale: 'en-GB',
        contentfulCountry: 'Germany',
        locale: 'en-de',
        serviceLocale: 'en-de',
        language: english,
        currency: 'EUR',
        link: 'https://www.no-ga.com/en-de',
        termsSlug: 'terms-online',
        loop54Market: 'de',
        adyenMarket: 'de',
        freeShippingThresholdSmallPackage:
            EU_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE,
        freeShippingThresholdLargePackage:
            EU_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE,
        externalStoreId: 'ecom-noga',
        searchPathName: 'search',
        adyenCheckoutEnabled: true,
        walleyCheckoutEnabled: false,
        hasTrustPilot: false,
    },
    // Austria
    {
        code: 'at',
        label: 'Austria',
        testfreaksClientId: envConfig.NEXT_PUBLIC_EU_TESTFREAKS_CLIENT_ID,
        searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL,
        contentfulLocale: 'en-GB',
        contentfulCountry: 'Austria',
        locale: 'en-at',
        serviceLocale: 'en-at',
        language: english,
        currency: 'EUR',
        link: 'https://www.no-ga.com/en-at',
        termsSlug: 'terms-online',
        loop54Market: 'at',
        adyenMarket: 'at',
        freeShippingThresholdSmallPackage:
            EU_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE,
        freeShippingThresholdLargePackage:
            EU_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE,
        externalStoreId: 'ecom-noga',
        searchPathName: 'search',
        adyenCheckoutEnabled: true,
        walleyCheckoutEnabled: false,
        hasTrustPilot: false,
    },
    // Spain
    {
        code: 'es',
        label: 'Spain',
        testfreaksClientId: envConfig.NEXT_PUBLIC_EU_TESTFREAKS_CLIENT_ID,
        searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL,
        contentfulLocale: 'en-GB',
        contentfulCountry: 'Spain',
        locale: 'en-es',
        serviceLocale: 'en-es',
        language: english,
        currency: 'EUR',
        link: 'https://www.no-ga.com/en-es',
        termsSlug: 'terms-online',
        loop54Market: 'es',
        adyenMarket: 'es',
        freeShippingThresholdSmallPackage:
            EU_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE,
        freeShippingThresholdLargePackage:
            EU_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE,
        externalStoreId: 'ecom-noga',
        searchPathName: 'search',
        adyenCheckoutEnabled: true,
        walleyCheckoutEnabled: false,
        hasTrustPilot: false,
    },
    // Netherlands
    {
        code: 'nl',
        label: 'Netherlands',
        testfreaksClientId: envConfig.NEXT_PUBLIC_EU_TESTFREAKS_CLIENT_ID,
        searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL_NL,
        contentfulLocale: 'nl-NL',
        contentfulCountry: 'Netherlands',
        locale: 'nl',
        serviceLocale: 'nl-nl',
        language: {
            code: 'nl',
            label: 'Dutch',
            flag: 'nl',
        },
        currency: 'EUR',
        link: 'https://www.no-ga.com/nl',
        termsSlug: 'algemene-voorwaarden-online-aankopen',
        loop54Market: 'nl',
        adyenMarket: 'nl',
        freeShippingThresholdSmallPackage:
            EU_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE,
        freeShippingThresholdLargePackage:
            EU_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE,
        externalStoreId: 'ecom-nl',
        searchPathName: 'zoeken',
        adyenCheckoutEnabled: true,
        walleyCheckoutEnabled: false,
        hasTrustPilot: false,
    },
    // Belgium
    {
        code: 'be',
        label: 'Belgium',
        testfreaksClientId: envConfig.NEXT_PUBLIC_EU_TESTFREAKS_CLIENT_ID,
        searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL,
        contentfulLocale: 'en-GB',
        contentfulCountry: 'Belgium',
        locale: 'en-be',
        serviceLocale: 'en-be',
        language: english,
        currency: 'EUR',
        link: 'https://www.no-ga.com/en-be',
        termsSlug: 'terms-online',
        loop54Market: 'be',
        adyenMarket: 'be',
        freeShippingThresholdSmallPackage:
            EU_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE,
        freeShippingThresholdLargePackage:
            EU_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE,
        externalStoreId: 'ecom-noga',
        searchPathName: 'search',
        adyenCheckoutEnabled: true,
        walleyCheckoutEnabled: false,
        hasTrustPilot: false,
    },
    // France
    {
        code: 'fr',
        label: 'France',
        testfreaksClientId: envConfig.NEXT_PUBLIC_EU_TESTFREAKS_CLIENT_ID,
        searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL,
        contentfulLocale: 'en-GB',
        contentfulCountry: 'France',
        locale: 'en-fr',
        serviceLocale: 'en-fr',
        language: english,
        currency: 'EUR',
        link: 'https://www.no-ga.com/en-fr',
        termsSlug: 'terms-online',
        loop54Market: 'fr',
        adyenMarket: 'fr',
        freeShippingThresholdSmallPackage:
            EU_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE,
        freeShippingThresholdLargePackage:
            EU_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE,
        externalStoreId: 'ecom-noga',
        searchPathName: 'search',
        adyenCheckoutEnabled: true,
        walleyCheckoutEnabled: false,
        hasTrustPilot: false,
    },
    // Finland
    {
        code: 'fi',
        label: 'Finland',
        testfreaksClientId: envConfig.NEXT_PUBLIC_FI_TESTFREAKS_CLIENT_ID,
        searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL_FI,
        contentfulLocale: 'fi-FI',
        contentfulCountry: 'Finland',
        locale: 'fi',
        serviceLocale: 'fi-fi',
        language: {
            code: 'fi',
            label: 'Finnish',
            flag: 'fi',
        },
        currency: 'EUR',
        link: 'https://www.no-ga.com/fi',
        termsSlug: 'ehdot-verkkokauppa',
        loop54Market: 'fi',
        adyenMarket: 'fi',
        freeShippingThresholdSmallPackage: 100,
        freeShippingThresholdLargePackage: 400,
        externalStoreId: 'ecom-fi',
        searchPathName: 'haku',
        adyenCheckoutEnabled: false,
        walleyCheckoutEnabled: true,
        walleyB2CStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2C_FI,
        walleyB2BStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2B_FI,
        walleyWidgetEnabled: true,
        hasTrustPilot: true,
    },
    // Sweden
    {
        code: 'se',
        label: 'Sweden',
        testfreaksClientId: envConfig.NEXT_PUBLIC_SE_TESTFREAKS_CLIENT_ID,
        searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL_SE,
        contentfulLocale: 'sv-SE',
        contentfulCountry: 'Sweden',
        locale: '',
        serviceLocale: 'sv-se',
        language: {
            code: 'sv',
            label: 'Swedish',
            flag: 'se',
        },
        currency: 'SEK',
        link: 'https://www.nordiskagalleriet.se',
        termsSlug: 'kopvillkor-e-handel',
        loop54Market: 'se',
        adyenMarket: 'se',
        freeShippingThresholdSmallPackage: 1000,
        freeShippingThresholdLargePackage: 3000,
        externalStoreId: 'ecom-se',
        searchPathName: 'sok',
        adyenCheckoutEnabled: false,
        walleyCheckoutEnabled: true,
        walleyB2CStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2C_SE,
        walleyB2BStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2B_SE,
        walleyWidgetEnabled: true,
        hasTrustPilot: true,
    },
    // Norway
    {
        code: 'no',
        label: 'Norway',
        testfreaksClientId: envConfig.NEXT_PUBLIC_NO_TESTFREAKS_CLIENT_ID,
        searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL_NO,
        contentfulLocale: 'nb-NO',
        contentfulCountry: 'Norway',
        locale: '',
        serviceLocale: 'nb-no',
        language: {
            code: 'nb',
            label: 'Norwegian',
            flag: 'no',
        },
        currency: 'NOK',
        link: 'https://www.nordiskagalleriet.no',
        termsSlug: 'kjopsvilkar-online',
        loop54Market: 'no',
        adyenMarket: 'no',
        freeShippingThresholdSmallPackage: 1000,
        freeShippingThresholdLargePackage: 3000,
        externalStoreId: 'ecom-no',
        searchPathName: 'sog',
        adyenCheckoutEnabled: false,
        walleyCheckoutEnabled: true,
        walleyB2CStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2C_NO,
        walleyB2BStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2B_NO,
        walleyWidgetEnabled: true,
        hasTrustPilot: true,
    },
    // Denmark
    {
        code: 'dk',
        label: 'Denmark',
        testfreaksClientId: envConfig.NEXT_PUBLIC_DK_TESTFREAKS_CLIENT_ID,
        searchEngineUrl: envConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL_DK,
        contentfulLocale: 'da-DK',
        contentfulCountry: 'Denmark',
        locale: 'dk',
        serviceLocale: 'da-dk',
        language: {
            code: 'da',
            label: 'Danish',
            flag: 'dk',
        },
        currency: 'DKK',
        link: 'https://www.no-ga.com/dk',
        termsSlug: 'kobsbetingelser-online',
        loop54Market: 'dk',
        adyenMarket: 'dk',
        freeShippingThresholdSmallPackage: 1000,
        freeShippingThresholdLargePackage: 3000,
        externalStoreId: 'ecom-dk',
        searchPathName: 'sogning',
        adyenCheckoutEnabled: false,
        walleyB2CStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2C_DK,
        walleyB2BStoreId: envConfig.NEXT_PUBLIC_WALLEY_STORE_CODE_B2B_DK,
        walleyCheckoutEnabled: true,
        walleyWidgetEnabled: false,
        hasTrustPilot: true,
    },
];

export const getMarketConfigFromLocale = (
    locale?: string,
): MarketConfiguration => {
    // Sweden and Norway have empty locale
    if (locale) {
        const market = marketConfigurations.find(
            (market) => market.locale === locale,
        );
        if (market) {
            return market;
        }
    }
    return getDefaultMarket();
};

export const getMarketConfigFromCode = (
    marketCode?: string,
): MarketConfiguration => {
    const market = marketConfigurations.find(
        (market) => market.code === marketCode,
    );
    if (market) {
        return market;
    }
    return getDefaultMarket();
};

export const getDefaultMarket = () => {
    const fallbackMarket = marketConfigurations.find(
        (market) => market.code === envConfig.NEXT_PUBLIC_APP_MARKET,
    );
    // null coalesce only for type safety, should never happen
    return fallbackMarket ?? marketConfigurations[0];
};

export const isValidLocale = (locale?: string) => {
    const appMarket = envConfig.NEXT_PUBLIC_APP_MARKET;

    if (appMarket === 'se') {
        return locale === getMarketConfigFromCode('se').locale;
    }

    if (appMarket === 'no') {
        return locale === getMarketConfigFromCode('no').locale;
    }

    const euMarkets = marketConfigurations.filter(
        (m) => m.code !== 'se' && m.code !== 'no',
    );

    return euMarkets.some((market) => market.locale === locale);
};

export const isValidServiceLocale = (serviceLocale?: string) => {
    const appMarket = envConfig.NEXT_PUBLIC_APP_MARKET;
    if (appMarket === 'se') {
        return serviceLocale === getMarketConfigFromCode('se').serviceLocale;
    }
    if (appMarket === 'no') {
        return serviceLocale === getMarketConfigFromCode('no').serviceLocale;
    }
    const euMarkets = marketConfigurations.filter(
        (m) => m.code !== 'se' && m.code !== 'no',
    );
    return euMarkets.some((market) => market.serviceLocale === serviceLocale);
};

export const getMarketConfigsSortedByLabel = () => {
    return marketConfigurations.sort((a, b) => a.label.localeCompare(b.label));
};

export function getLink(from: MarketConfiguration, to: MarketConfiguration) {
    if (isSameBaseUrl(from, to)) {
        return envConfig.NEXT_PUBLIC_APP_BASE_URL + '/' + to.locale;
    }
    return to.link;
}

function isSameBaseUrl(from: MarketConfiguration, to: MarketConfiguration) {
    if (from.code === to.code) {
        return true;
    }
    const singleSiteCodes = ['se', 'no'];
    return (
        !singleSiteCodes.includes(from.code) &&
        !singleSiteCodes.includes(to.code)
    );
}

/** These are not with the other configs because only simple
 * objects / types can be passed between server and client,
 * and RegExp is not such a simple type.
 */
export const getZipCodeRegexByMarketCode = (marketCode: MarketCode) => {
    switch (marketCode) {
        case 'eu':
            return /^\s*\d{5}\s*$/;
        case 'de':
            return /^\s*\d{5}\s*$/;
        case 'at':
            return /^\s*\d{4}\s*$/;
        case 'es':
            return /^\s*\d{5}\s*$/;
        case 'nl':
            return /^\s*\d{4}\s?[A-Za-z]{2}\s*$/;
        case 'be':
            return /^\s*\d{4}\s*$/;
        case 'fr':
            return /^\s*\d{5}\s*$/;
        case 'fi':
            return /^\s*\d{5}\s*$/;
        case 'se':
            return /^\s*\d{3}\s?\d{2}\s*$/;
        case 'no':
            return /^\s*\d{4}\s*$/;
        case 'dk':
            return /^\s*\d{4}\s*$/;
        case 'ag':
            return /dsfa/;
    }
};

/**
 * Map our locale to the the correspondig localized Adyen locale
 * to make sure we use a supported language in the Adyen drop-in widget
 * @url https://docs.adyen.com/online-payments/build-your-integration/sessions-flow/?platform=Web&integration=Drop-in&version=6.0.3#localization -  Adyen localization doumentation
 * @url https://github.com/Adyen/adyen-web/tree/main/packages/server/translations - List of all supported locales
 */
export function mapLocaleToAdyenLocale(locale?: string) {
    switch (locale) {
        case 'en-GB':
            return 'en-US';
        case 'nb-NO':
            return 'no-NO';
        case 'sv-SE':
            return 'sv-SE';
        case 'fi-FI':
            return 'fi-FI';
        case 'da-DK':
            return 'da-DK';
        case 'nl-NL':
            return 'nl-NL';
        default:
            return 'en-US';
    }
}

/** @deprecated We should work toward moving this into the rest of the configs. */
export const HREFLANG_MAP: Record<string, string> = {
    'en-GB': 'https://www.no-ga.com/eu',
    'en-AT': 'https://www.no-ga.com/en-at',
    'en-BE': 'https://www.no-ga.com/en-be',
    'en-DE': 'https://www.no-ga.com/en-de',
    'en-NL': 'https://www.no-ga.com/en-nl',
    'en-ES': 'https://www.no-ga.com/en-es',
    'fi-FI': 'https://www.no-ga.com/fi',
    'da-DK': 'https://www.no-ga.com/dk',
    'nb-NO': 'https://www.nordiskagalleriet.no',
    'sv-SE': 'https://www.nordiskagalleriet.se',
};

export const determineSiteName = () => {
    switch (envConfig.NEXT_PUBLIC_APP_MARKET) {
        case 'se':
            return 'ngse';
        case 'no':
            return 'ngno';
        case 'de':
            return 'ngeu';
        default:
            throw new Error(
                'Unable to determine site name, invalid `env.NEXT_PUBLIC_APP_MARKET`',
            );
    }
};
