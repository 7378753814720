import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment editorialPageEntry on Entry {
        __typename
        sys {
            id
        }
    }

    fragment editorialPageEditorialRow on EditorialRow {
        ...editorialPageEntry
        title
        numberOfColumns
        width
        spacing
        contentCollection(limit: 4) {
            items {
                ...editorialPageEditorialBlock
                ...editorialPageEditorialSplash
                ...editorialPageEditorialProducts
                ...editorialPageStore
            }
        }
    }

    fragment editorialPageEditorialBlock on EditorialBlock {
        ...editorialPageEntry
        content {
            json
            links {
                entries {
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                    }
                }
            }
        }
        verticalAlignment
    }

    fragment editorialPageEditorialSplash on EditorialSplash {
        ...editorialPageEntry
        type
        text {
            json
            links {
                entries {
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                    }
                    block {
                        ...editorialPageNavigationLink
                        ...editorialPageForm
                    }
                }
            }
        }
        textColor
        desktopImage {
            ...contentfulAsset
        }
        mobileImage {
            ...contentfulAsset
        }
        desktopVideo {
            ...contentfulAsset
        }
        mobileVideo {
            ...contentfulAsset
        }
        imageText {
            json
            links {
                entries {
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                    }
                    block {
                        __typename
                        sys {
                            id
                        }
                    }
                }
            }
        }
    }

    fragment editorialPageEditorialGallery on EditorialGallery {
        ...editorialPageEntry
        imagesCollection(limit: 12) {
            items {
                ...editorialPageEntry
                media
                imageText {
                    json
                }
            }
        }
    }

    fragment editorialPageEditorialProducts on EditorialProducts {
        ...editorialPageEntry
        commerceData
    }

    fragment editorialPageLinkList on LinkList {
        ...editorialPageEntry
        heading
        linksCollection(limit: 10) {
            items {
                ... on Page {
                    title
                    slug
                }
            }
        }
    }

    fragment editorialPageNavigationLink on NavigationLink {
        ...editorialPageEntry
        name
        type
        title
        display
        variant
        externalLink
        internalLink {
            ... on Page {
                slug
                commerceData
            }
        }
    }

    fragment editorialPageForm on Form {
        ...editorialPageEntry
        name
        reference
        dictionaryCollection(limit: 100) {
            items {
                ... on KeyValue {
                    key
                    name
                    value
                }
            }
        }
    }

    fragment editorialPageStore on Store {
        ...editorialPageEntry
        title
        description {
            json
            links {
                entries {
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        type
        infoText
        phoneNumber
        email
        address
        city
        postalCode
        googleMapsUrl
        storePage {
            ... on Page {
                slug
            }
        }
        storePageLinkText
        openingHoursCollection(limit: 7) {
            items {
                name
                openingTime
                closingTime
                dayOfTheWeek
                closed
            }
        }
        exceptionalOpeningHoursCollection(limit: 5) {
            items {
                name
                date
                title
                closed
                closingTime
                openingTime
            }
        }
    }

    fragment editorialPageCategory on Page {
        ...editorialPageEntry
        title
        slug
        linkedFrom(allowedLocales: "sv-SE") {
            editorialPageCollection(limit: 4) {
                items {
                    ...editorialPageRelation
                }
            }
        }
    }

    fragment editorialPageRelation on EditorialPage {
        ...editorialPageEntry
        mobileHeroImage {
            ...contentfulAsset
        }
        desktopHeroImage {
            ...contentfulAsset
        }
        slug
        title
        publishingDate
        ingress {
            json
        }
    }
`);

export default graphql(/* GraphQL */ `
    query EditorialPage(
        $locale: String
        $id: String!
        $preview: Boolean
        $activeMarkets: [String]
    ) {
        editorialPage(locale: $locale, id: $id, preview: $preview) {
            ...editorialPageEntry
            slug
            title
            heroImageAltText
            publishingDate
            mobileHeroImage {
                ...contentfulAsset
            }
            desktopHeroImage {
                ...contentfulAsset
            }
            metaTitle
            metaDescription
            metaKeywords
            heroText {
                json
            }
            ingress {
                json
            }
            category {
                ... on Page {
                    ...editorialPageCategory
                }
            }
            tagsCollection(limit: 10) {
                items {
                    ...editorialPageEntry
                    key
                    value
                }
            }
            footerBanner(
                where: { activeMarkets_contains_some: $activeMarkets }
            ) {
                ...footerBanner
            }
            content {
                json
                links {
                    entries {
                        block {
                            ...editorialPageEditorialGallery
                            ...editorialPageEditorialSplash
                            ...editorialPageEditorialRow
                            ...editorialPageEditorialProducts
                            ...editorialPageNavigationLink
                        }
                        hyperlink {
                            sys {
                                id
                            }
                            ... on Page {
                                slug
                            }
                            ... on EditorialPage {
                                slug
                            }
                            ... on LandingPage {
                                slug
                            }
                        }
                    }
                }
            }
        }
    }
`);
