import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment sideMenuEntry on Entry {
        __typename
        sys {
            id
        }
    }

    fragment sideMenuSplash on Splash {
        ...sideMenuEntry
        name
        href {
            ... on Page {
                slug
                title
                commerceData
                commerceId
                commerceName
            }
        }
        type
        header
        linkText
        textColor
        subheading
    }

    fragment sideMenuNavigationLink on NavigationLink {
        ...sideMenuEntry
        type
        title
        display
        variant
        title
        internalLink {
            ... on Page {
                slug
                title
                commerceData
                commerceId
                commerceName
            }
            ... on EditorialPage {
                slug
                title
            }
            ... on LandingPage {
                slug
                title
            }
            ... on Campaign {
                slug
                title: campaignTitle
            }
        }
    }
    fragment sideMenuNavigation on Navigation {
        ...sideMenuEntry
        name
        title
        sublinksCollection(limit: 12) {
            items {
                ...sideMenuNavigationLink
            }
        }
    }
    fragment sideMenuStoreInfo on Store {
        ...sideMenuEntry
        title
        description {
            json
            links {
                entries {
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        type
        infoText
        phoneNumber
        email
        address
        city
        postalCode
        googleMapsUrl
        storePage {
            ... on Page {
                slug
            }
        }
        storePageLinkText
        openingHoursCollection(limit: 7) {
            items {
                name
                openingTime
                closingTime
                dayOfTheWeek
                closed
            }
        }
        exceptionalOpeningHoursCollection(limit: 5) {
            items {
                name
                date
                title
                closed
                closingTime
                openingTime
            }
        }
    }
`);

export default graphql(/* GraphQL */ `
    query SideMenu($locale: String!, $id: String!, $preview: Boolean) {
        sideMenu(id: $id, locale: $locale, preview: $preview) {
            ...sideMenuEntry
            name
            navigationsCollection(limit: 2) {
                items {
                    ...sideMenuNavigation
                }
            }
            storeInfo {
                ...sideMenuStoreInfo
            }
        }
    }
`);
